define("discourse/plugins/discourse-autopost/discourse/templates/admin/plugins-autopost", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-plugin-autopost">
    <div class="admin-controls">
      <div class="span15">
        <ul class="nav nav-pills">
          {{nav-item
            route="adminPlugins.autopost.campaigns"
            label="autopost.campaigns.title"
          }}
        </ul>
      </div>
    </div>
    <div class="admin-container">
      {{outlet}}
    </div>
  </div>
  */
  {
    "id": "yLa1/9jh",
    "block": "[[[10,0],[14,0,\"admin-plugin-autopost\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"span15\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"route\",\"label\"],[\"adminPlugins.autopost.campaigns\",\"autopost.campaigns.title\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"ul\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-autopost/discourse/templates/admin/plugins-autopost.hbs",
    "isStrictMode": false
  });
});