define("discourse/plugins/discourse-autopost/discourse/initializers/autopost-init", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-autopost/discourse/components/youtube-summary-button"], function (_exports, _pluginApi, _youtubeSummaryButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-autopost",
    initialize(container) {
      let siteSettings = container.lookup("site-settings:main");
      let currentUser = container.lookup("service:currentUser");
      if (!siteSettings.autopost_enabled || !currentUser || !currentUser.staff) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.registerValueTransformer("post-menu-buttons", _ref => {
          let {
            value: dag,
            context: {
              secondLastHiddenButtonKey // key of the second last hidden button
            }
          } = _ref;
          dag.add("youtube-summary-button", _youtubeSummaryButton.default, {
            before: [secondLastHiddenButtonKey]
          });
        });
      });
    }
  };
});