define("discourse/plugins/discourse-autopost/discourse/components/youtube-summary-button", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SolvedAcceptAnswerButton extends _component.default {
    summariseVideo() {
      (0, _ajax.ajax)(`/autopost/${this.args.post.id}.json`, {
        type: "POST"
      }).then(() => {
        // all good;
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "summariseVideo", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__youtube-summary-robot"
          ...attributes
          @action={{this.summariseVideo}}
          @icon="robot"
          @title="youtube_summary.process_post"
        />
      
    */
    {
      "id": "r/mN12Yi",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__youtube-summary-robot\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"summariseVideo\"]],\"robot\",\"youtube_summary.process_post\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-autopost/discourse/components/youtube-summary-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SolvedAcceptAnswerButton;
});